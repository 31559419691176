<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input class="width_100" v-model="search.id" placeholder="请输入漫画ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">标题：</span>
        <el-input class="width_250" v-model="search.title" placeholder="请输入标题关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">专辑：</span>
        <el-select v-model="search.cartoon_id" filterable placeholder="全部" size="medium" clearable>
          <el-option key="-1" label="全部" value="0"></el-option>
          <el-option v-for="item in cartoonSelectList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.status" placeholder="全部" size="medium" clearable>
          <el-option key="-1" label="全部" value="-1"></el-option>
          <el-option v-for="item in statusList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getChapterList()">查询</el-button>
      </div>
      <br>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="chapterShow()" class="el-icon-circle-plus">新增漫画章节</el-button>
        <el-button type="primary" size="medium" class="el-icon-tickets" @click="showcartoonList()" >专辑管理</el-button>
        <el-button type="primary" size="medium" @click="chapterStatusMulti(1)">批量上架</el-button>
        <el-button type="primary" size="medium" @click="chapterStatusMulti(2)">批量下架</el-button>
        <el-button type="primary" size="medium" @click="MultiFree()">批量免费</el-button>
        <el-button type="primary" size="medium" @click="showZipRar()">ZIP/RAR上传</el-button>
        <el-button type="primary" size="medium" @click="updateSelect()">下拉框数据更新</el-button>
      </div>
    </div>

    <el-table :data="tableData"  @selection-change="handelChapterSelection" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="image" label="封面图" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <ximg :src="scope.row.image"  :width="30" :height="30" alt=""></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="chapter" label="章节" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="cartoon_id" label="专辑" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{ getcartoonById(scope.row.cartoon_id) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_free" label="免费" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch :active-value=1 @click="chapterFree(scope.row)" :inactive-value=0 :value="scope.row.is_free" ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.status == 0" style="color: #4f5555">未发布</span>
          <span v-if="scope.row.status == 1" style="color: #1d953f">已上架</span>
          <span v-if="scope.row.status == 2" style="color: #f15b6c">已下架</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="content" label="简介" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template #default="scope">
          <span class="operation" @click="chapterUploadImage(scope.row)">图片管理</span>
          <span class="operation" @click="chapterShow(scope.row)">编辑</span>
          <span class="operation" @click="delChapter(scope.row)">删除</span>
          <span class="operation" v-if="scope.row.status == 1" @click="chapterStatus(scope.row,2)">下架</span>
          <span class="operation" v-if="scope.row.status == 2" @click="chapterStatus(scope.row,1)">上架</span>
          <span class="operation" v-if="scope.row.status == 0" @click="chapterStatus(scope.row,1)" >上架</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getChapterList()" />


    <!--  漫画专辑列表  -->
    <el-dialog title="专辑管理" v-model="cartoonVisible" width="90%" height="500px" top="5vh" :close-on-click-modal="false" >
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input class="width_100" v-model="cartoon.search.id" placeholder="请漫画ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">标题：</span>
        <el-input class="width_250" v-model="cartoon.search.title" placeholder="请输入标题关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="cartoon.search.status" filterable placeholder="全部" size="medium" clearable>
          <el-option label="全部" :value=-1></el-option>
          <el-option v-for="item in statusList" :key="item" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getcartoonList()">查询</el-button>
        <el-button type="primary" size="medium" @click="cartoonEdit()">添加</el-button>
        <el-button type="primary" size="medium" @click="multicartoonStatus(1)">批量上架</el-button>
        <el-button type="primary" size="medium" @click="multicartoonStatus(2)">批量下架</el-button>
        <el-button type="primary" size="medium" @click="multicartoonDel()">批量删除</el-button>
      </div>
      <el-table :data="cartoon.tableData"  @selection-change="handelcartoonSelection" :header-cell-style="{background:'#F7F8FA'}">
        <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="image" label="封面图" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <ximg :src="scope.row.image" :width="30" :height="30" alt=""></ximg>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="author" label="作者" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="introduction" label="简介" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{ getTypesById(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="标签" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-for="item of scope.row.tags" :key="item" >{{item.Title}},</span>
          </template>
        </el-table-column>
        <el-table-column prop="chapters" label="章节" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="is_done" label="完结" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.is_done == 0">未完结</span>
            <span v-if="scope.row.is_done == 1" style="color: #1d953f">完结</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.status == 0" style="color: #4f5555">未发布</span>
            <span v-if="scope.row.status == 1" style="color: #1d953f">已上架</span>
            <span v-if="scope.row.status == 2" style="color: #f15b6c">已下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="view_number" label="浏览量" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="goods_count" label="点赞量" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="300px" align="center">
          <template #default="scope">
            <span class="operation" v-if="scope.row.is_done == 0" @click="isDone(scope.row,1)">完结</span>
            <span class="operation" v-if="scope.row.is_done == 1" @click="isDone(scope.row,0)">未完结</span>
            <span class="operation" @click="cartoonEdit(scope.row)">编辑</span>
            <span class="operation" @click="cartoonDel(scope.row)">删除</span>
            <span class="operation" v-if="scope.row.status == 1" @click="cartoonStatus(scope.row,2)">下架</span>
            <span class="operation" v-if="scope.row.status == 2" @click="cartoonStatus(scope.row,1)">上架</span>
            <span class="operation" v-if="scope.row.status == 0" @click="cartoonStatus(scope.row,1)" >上架</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 50px">
        <page :pager="cartoon.pager" @query="getcartoonList()" />

      </div>

    </el-dialog>

    <el-dialog :title="cartoonAddTitle" v-model="cartoonAddVisible" width="1200" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="2" align="right">标题</el-col>
        <el-col :span="22">
          <el-input placeholder="请输入专辑标题" v-model="cartoon.add.title"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" align="right">作者</el-col>
        <el-col :span="22">
          <el-input placeholder="请输入专辑作者" v-model="cartoon.add.author"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" align="right">简介</el-col>
        <el-col :span="22">
          <el-input type="textarea" placeholder="请输入专辑简介" v-model="cartoon.add.introduction"></el-input>
        </el-col>
      </el-row>
      <el-row v-if="cartoon.add.id > 0">
        <el-col :span="2" align="right">章节</el-col>
        <el-col :span="22">
          <el-input  placeholder="请输入专辑章节" v-model="cartoon.add.chapter"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" align="right">封面图</el-col>
        <el-col :span="22">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadImg"
              accept="image/*"
              :before-upload="beforeAvatarUpload">
            <ximg  :enlarge="false" v-if="imagecartoonUrl" :src="imagecartoonUrl" class="avatar"></ximg>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" align="right">分类</el-col>
        <el-col :span="22">
          <el-select class="width_200" v-model="cartoon.add.type">
            <el-option value="0" label="请选择"></el-option>
            <el-option v-for="item of Types" :key="item" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" align="right">标签</el-col>
        <el-col :span="22">
          <el-select style="width:90%" v-model="cartoon.add.tags" multiple>
            <el-option v-for="item of Tags" :key="item" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"></el-col>
        <el-col :span="16">
          <el-button @click="cartoonAddVisible = false">关闭</el-button>
          <el-button type="primary" @click="savecartoon()">保存</el-button>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
    </el-dialog>


    <el-dialog :title="chapterAddTitle" v-model="chapterAddVisible" width="600px" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">标题</el-col>
        <el-col :span="20">
          <el-input placeholder="请输入漫画标题" v-model="chapterAdd.title"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">专辑</el-col>
        <el-col :span="20">
          <el-select class="width_250" filterable v-model="chapterAdd.cartoon_id" placeholder="请选择">
            <el-option v-for="item of cartoonSelectList" :key="item" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">章节</el-col>
        <el-col :span="20">
          <el-input type="number" placeholder="请输入漫画章节" v-model="chapterAdd.chapter"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">是否免费</el-col>
        <el-col :span="20">
          <el-switch v-model="chapterAdd.is_free" :active-value=1 :inactive-value=0 ></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">封面图</el-col>
        <el-col :span="20">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadChapterImg"
              accept="image/jpeg,image/png"
              :before-upload="beforeAvatarUpload">
            <img v-if="imageChapterUrl" :src="imageChapterUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="chapterAddVisible = false">关闭</el-button>
          <el-button @click="saveChapter()">保存</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog :title="chapterUploadSubImage.dialog_title" v-model="chapterImageListVisible" width="80%" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="24">
          <el-button @click="getChapterSubImage()">刷新</el-button>
          <el-button type="primary" @click="chapterUploadSubimageShow()">上传图片</el-button>
          <span></span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" >
          <el-table :data="chapterUploadSubImage.tableData" max-height="800" :header-cell-style="{background:'#F7F8FA'}">
<!--            <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>-->
            <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="image" label="缩略图" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <img :src="this.$store.getImage(scope.row.image)" @click="showSubBigImages(scope.$index)" width="30" height="30" v-if="scope.row.image !== ''" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="title" width="300px" label="标题" align="left" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="weight" label="排序(点击两秒后自动排序)" width="200" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span>{{scope.row.weight}}</span>
                &nbsp;&nbsp;
                <i class="el-icon-sort-down" @click="chapterImageSort(scope.$index,scope.row,-1)"></i>
                <i class="el-icon-sort-up" @click="chapterImageSort(scope.$index,scope.row,1)"></i>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px" align="center">
              <template #default="scope">
                <span class="operation" @click="chapterImageDel(scope.$index,scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>
    <input type="file" ref="chapterUpload" style="display: none" multiple @change="handleChapterSubimageUpload" accept="image/jpeg,image/png">
    <el-dialog :title="chapterUploadSubImage.dialog_title" v-model="chapterImageUploadVisible" width="600px" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="24">
<!--          <el-button size="small" @click="this.$refs.chapterUpload.click()" type="primary">选择图片</el-button>-->
          <el-button size="small" @click="startUploadChapterSubImage()" type="success">开始上传</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table :data="chapterUploadSubImage.list" :header-cell-style="{background:'#F7F8FA'}">
            <el-table-column  prop="img" label="图片" align="left" width="60" >
              <template #default="scope">
                <img :src="scope.row.url" width="30" height="30" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名字" align="left" ></el-table-column>
            <el-table-column prop="status" width="100" label="状态" align="left">
              <template #default="scope">
                <span v-if="scope.row.status == 0" style="color: #4f5555">上传中</span>
                <span v-if="scope.row.status == 1" style="color: #1d953f">上传成功</span>
                <span v-if="scope.row.status == 2" style="color: #f15b6c">上传失败</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60" align="left" >
              <template #default="scope">
                <span class="operation" @click="chapterUploadSubImage.list.splice(scope.$index,1)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>


    <el-dialog title="RAR/ZIP上传" v-model="dialogRarVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadZip"
              accept="application/zip,application/x-rar"
              :before-upload="beforeZipUpload">
            <el-button type="primary" size="small">选择文件</el-button>
          </el-upload>
        </el-col>
        <el-col :span="20">
          <el-progress :text-inside="true" :stroke-width="18" :percentage="filePercentage"></el-progress>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">
          目录结构
        </el-col>
        <el-col :span="20">
          <el-input type="textarea" readonly :autosize="{ minRows: 8, maxRows: 18}" value="漫画.rar
--漫画/3D心软妈妈  (目录就是专辑标题哦,更多设置请在【详细信息.txt】中定义)
    --漫画/3D心软妈妈/详细信息.txt
    --漫画/3D心软妈妈/第一话/000001.jpg
    --漫画/3D心软妈妈/第一话/000002.jpg
    --漫画/3D心软妈妈/我是封面图.png
    --漫画/3D心软妈妈/第二话/000001.jpg
    --漫画/3D心软妈妈/第二话/000002.jpg
--漫画/每日中出
">
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">
          详细信息.txt内容:
        </el-col>
        <el-col :span="20">
          <el-input type="textarea" readonly :autosize="{ minRows: 13, maxRows: 18}" value="#标题可以不写，不写的话目录就是标题
标题=亡灵沸腾
# #号开头的行，不会被收录，每一行的空格会自动去掉
作者=妄语
简介=我是亡灵沸腾，牛逼plus
# 跟【详细信息.txt】同级目录的图片
封面图=我是封面图.png
背景图=背景图.png
#单个整数
分类=1
#多个标签逗号隔开
标签=1,2
"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"></el-col>
        <el-col :span="18">
          <el-button @click="dialogRarVisible=false,filePercentage=0">取消</el-button>
          <el-button @click="saveRar()">保存</el-button></el-col>
      </el-row>
      <div style="height: 20px"></div>
    </el-dialog>

    <el-dialog title="批量设置免费" v-model="chapterFreeVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">章节ID:</el-col>
        <el-col :span="20">
          <span>{{chapterFreeDialog.id_str}}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">是否免费</el-col>
        <el-col :span="20">
          <el-switch v-model="chapterFreeDialog.is_free" :active-value=1 :inactive-value=0 ></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="chapterFreeVisible = false">关闭</el-button>
          <el-button @click="saveMultiFree()">保存</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import httpClient from '@/config/httpClient.js'
import page from "@/components/page";
export default {
  name: "community_cartoon",
  components: {
    page,
  },
  data() {
    return {
      chapterFreeDialog:{
        id_str:"",
        is_free:0,
      },
      chapterFreeVisible:false,
      fileList2: [], // {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}
      pager: {total: 0, page: 1, rows: 20},
      search: {
        //搜索
        id: "", //id
        title: [], //时间
        status:null,
        cartoon_id:null,
      },
      statusList:[
        {id:0,value:"未发布"},
        {id:1,value:"上架"},
        {id:2,value:"下架"},
      ],
      Types:[],
      Tags:[],
      cartoonSelectList:[], // 漫画专辑列表，下拉框用得
      tableData: [
        // {id:1,username:"ssss",title:"我的第一次",book:"海贼王",goods_count:999,is_free:true,status:0},
      ], //数据
      chapterAddTitle:"添加漫画章节",
      chapterAdd:{ // 漫画添加框
        row:null,
        id:0,
        title:"",
        cartoon_id:null,
        is_free:false,
        chapter:0,
        image:"",
      },
      chapterUploadVisible:false,
      chapterAddVisible:false,
      chapterImageListVisible:false,
      chapterImageUploadVisible:false,
      cartoonVisible:false,
      cartoonAddVisible:false,
      cartoonAddTitle:"漫画专辑添加",
      cartoon:{
        pager:{total: 0, page: 1, rows: 20},
        search:{
          id:0,
          title:"",
          checked:0,
          status:-1
        },
        tableData:[
          // {id:1,title:"我的第一次",image:"封面图",author:"海贼王",is_done:0,chapters:3200,introduction:"简介",add_time:"2022-01-01",view_number:999,tags:"标签",goods_count:999,status:1},
        ],
        add:{
          id:0,
          chapter:0,
          title:"",
          type:null,
          author:"",
          introduction:"",
          tags:[],
          image:"",
        },
      },
      imagecartoonUrl:"",// 漫画封面图
      imageChapterUrl:"",// 漫画章节封面图
      compress_height:320, // 压缩尺寸
      compress_width:226, // 压缩尺寸
      chapterMultiSelection:[],
      cartoonMultiSelection:[],
      chapterUploadSubImage:{
        list:[],
        tableData:[],
        row:null,
        title:"",
        cartoon_id:null,
        dialog_title:"",
      },
      sort_timer:null,
      dialogRarVisible:false,
      filePercentage:0,
      per_slice: 1024 * 1024, // 2M
      hashInfo: {
        hash:"",
        filename:"",
      }, // 保存到数据库的hash
    };
  },
  mounted() {
    this.updateSelect()
    this.getChapterList()
  },
  methods:{
    saveMultiFree(){
      httpClient("chapterMultiFree").post({is_free:this.chapterFreeDialog.is_free,ids:this.chapterFreeDialog.id_str,type:0}).then((res)=> {
        if(res.code == 0){
          for (const row of this.chapterMultiSelection){
            row.is_free = this.chapterFreeDialog.is_free
          }
          this.chapterFreeVisible = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    MultiFree(){
      if (this.chapterMultiSelection.length == 0){
        this.$message({message:"请选择要设置的章节!",type:"error"})
        return
      }
      var ids = []
      for (const row of this.chapterMultiSelection){
        ids.push(row.id)
      }
      this.chapterFreeDialog.id_str = ids.join(",")
      this.chapterFreeVisible = true
    },
    showSubBigImages(index){
      var list = []
      for(const row of this.chapterUploadSubImage.tableData){
        list.push({
          url:this.$store.getImage(row['big_image'])
        })
      }
      this.$common.showBigImages(list,index)
    },
    showBigImage(url){
      this.$common.showBigImages([{url:this.$store.getImage(url)}],0)
    },
    saveRar(){
      if (this.hashInfo.hash == ""){
        this.$message({message:"请选择要上传的文件!",type:"error"})
        return
      }
      this.$common.showLoading()
      httpClient("importByRar").post({type:0,hash:this.hashInfo.hash}).then((res)=>{
        console.log(res)
        this.$common.hideLoading()
        if (res.code == 0){
          this.filePercentage = 0
          this.hashInfo = {
            hash:"",
            filename:"",
          }
          this.$message({message:"导入完毕!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    beforeZipUpload(file){
      console.log(file)
    },
    uploadZip(param){
      this.filePercentage = 0
      if (param.file.size > 1024 * this.per_slice){
        // 不能超过1G
        this.$message({message:"上传的压缩包不可以超过1G",type:"error"})
        return
      }
      httpClient().setUploadUrl("").uploadSlice(param.file, (percentage)=>{
        this.filePercentage = percentage
      },(res)=>{
        console.log(res)
        this.hashInfo = res
        this.filePercentage = 100
        // 服务器解压，然后导入数据库
      },(err)=>{
        console.log(err)
      })
    },
    showZipRar(){
      this.dialogRarVisible = true
    },
    chapterImageSort(index,row,sort){
      if(this.sort_timer){
        clearInterval(this.sort_timer)
      }
      if(sort == 1){
        // 上移
        if(index == 0){
          return
        }
        var up_row = this.chapterUploadSubImage.tableData[index-1]
        this.chapterUploadSubImage.tableData[index-1] = row
        this.chapterUploadSubImage.tableData[index] = up_row
      }else{
        // 下移
        if(index == this.chapterUploadSubImage.tableData.length -1){
          return
        }
        var down_row = this.chapterUploadSubImage.tableData[index+1]
        this.chapterUploadSubImage.tableData[index+1] = row
        this.chapterUploadSubImage.tableData[index] = down_row
      }
      var weightlist = []
      for(var i in this.chapterUploadSubImage.tableData){
        var sort_row = this.chapterUploadSubImage.tableData[i]
        weightlist.push(sort_row.id+":"+(parseInt(i)+1))
      }
      if(weightlist.length == 0){
        console.log("没有任何改变")
        return;
      }
      this.sort_timer = setInterval(()=>{
        // 1秒后自动排序  再次移动则会停止
        httpClient("UpdateCartoonWeight").post({params:weightlist.join(",")}).then((res)=>{
          if(res.code > 0){
            this.$message({message:res.msg,type:"error"})
            // 重新刷新接口
          }
          this.getChapterSubImage()
        })
        clearInterval(this.sort_timer)
      },2000)
    },
    chapterImageDel(index,row){
      httpClient("DelChaptersContent").post({id:row.id}).then((res)=>{
        if(res.code == 0){
          this.chapterUploadSubImage.tableData.splice(index,1)
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    chapterUploadSubimageShow(){
      this.chapterImageUploadVisible=true
      this.chapterUploadSubImage.list=[]
      this.$refs.chapterUpload.click()
    },
    handleChapterSubimageUpload(event){
      for(const file of event.target.files){
        this.chapterUploadSubImage.list.push({
          name:file.name,
          url:URL.createObjectURL(file),
          status:0,
          filename:"", // 上传成功的地址
          file:file,
        })
      }
    },
    startUploadChapterSubImage(){
      if(this.chapterUploadSubImage.list.length == 0){
        this.$message({message:"请先选择图片!",type:"error"})
        return
      }
      var done = true
      var i = 0;
      for(const file of this.chapterUploadSubImage.list){
        i++
        if (file.status == 1){
          continue
        }
        done = false
        // 开始上传
        this.startUploadImg(file.file,(data)=>{
          file.filename = data.filename
          httpClient("AddChaptersContent").post({
            "chapter_id":this.chapterUploadSubImage.row.id,
            "title":file.name,
            "image":data.compress_img,
            "big_image":data.filename,
            "weight":parseInt(this.chapterUploadSubImage.tableData.length)+i,
          }).then((res)=>{
            if(res.code == 0){
              file.status = 1
              this.getChapterSubImage()
            }else{
              file.status = 2
            }
          })
        },true,this.compress_width,this.compress_height)
      }
      if (done){
        this.$message({message:"所有图片都已经上传完毕!",type:"success"})
      }
    },
    chapterFree(row){
      this.$common.showLoading()
      httpClient("IsFree").post({"id":row.id,"is_free":row.is_free == 0?1:0}).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.is_free = row.is_free == 0?1:0
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    handelChapterSelection(rows){
      this.chapterMultiSelection = rows;
    },
    chapterStatusMulti(status){
      if(this.chapterMultiSelection.length == 0){
        this.$message({message:"请先选择章节",type:"error"})
        return;
      }
      var ids = [];
      for(const row of this.chapterMultiSelection){
        ids.push(row.id)
      }
      var title = "下架"
      if(status == 1){
        title = "上架"
      }
      this.$confirm("批量"+title+": " + ids.join(",") + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        this.$common.showLoading()
        httpClient("BatchShelvesCartoonChapter").post({"ids":ids.join(","), "status":status}).then((res)=>{
          this.$common.hideLoading()
          if (res.code==0){
            for(const row of this.chapterMultiSelection){
              row.status = status
            }
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      })

    },
    chapterStatus(row,status){
      this.$common.showLoading()
      httpClient("BatchShelvesCartoonChapter").post({"ids":row.id, "status":status}).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.status = status
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    cartoonDel(row){
      this.$confirm("删除漫画专辑: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        this.$common.showLoading("删除漫画专辑:"+row.title)
        httpClient("DelCartoon").post({"id":row.id}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.getcartoonList()
            this.getcartoonSelectList()
          }else{
            this.$message({message:"添加漫画章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      })
    },
    delChapter(row){
      // DelCartoonChapters
      this.$confirm("删除漫画章节: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        this.$common.showLoading("删除漫画章节:"+row.title)
       httpClient("DelCartoonChapters").post({"id":row.id}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.getChapterList()
          }else{
            this.$message({message:"添加漫画章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      })

    },
    getcartoonById(cartoon_id){
      for(const cartoon of this.cartoonSelectList){
        if (cartoon.id == cartoon_id){
          return cartoon.title
        }
      }
      return "未知专辑ID:"+cartoon_id
    },
    saveChapter(){
      if (this.chapterAdd.id > 0){
        // 编辑
        var saveData = {"title":this.chapterAdd.title,
          "is_free":this.chapterAdd.is_free,
          "chapter":this.chapterAdd.chapter,
          "cartoon_id":this.chapterAdd.cartoon_id,
          "image":this.chapterAdd.image,
          "id":this.chapterAdd.id}
        httpClient("UpdateCartoonChapters").post(saveData).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.chapterAddVisible = false
            for(var key in saveData){
              this.chapterAdd.row[key] = saveData[key]
            }
          }else{
            this.$message({message:"添加漫画章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }else{
        // 添加
        this.$common.showLoading("正在添加漫画专辑!")
        httpClient("AddCartoonChapters").post(
          {"title":this.chapterAdd.title,
          "is_free":this.chapterAdd.is_free,
          "chapter":this.chapterAdd.chapter,
          "cartoon_id":this.chapterAdd.cartoon_id,
          "image":this.chapterAdd.image}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.chapterAddVisible = false
            this.getChapterList()
          }else{
            this.$message({message:"添加漫画章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }
    },
    cartoonStatus(row,status){
      this.$common.showLoading()
      httpClient("BatchShelvesCartoon").post({"ids":row.id,"status":status}).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.status = status
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    updateSelect(){
      this.getTypes()
      this.getTags()
      this.getcartoonSelectList()
    },
    uploadChapterImg(param){
      // 750*360
      var canvas = document.createElement("canvas")
      // 读取图片的宽高
      var img = document.createElement("img")
      img.src =  URL.createObjectURL(param.file)
      img.width = 750
      img.height = 360
      var self = this
      img.onload = function (){
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext("2d").drawImage(img,0,0,img.width,img.height)
        self.imageChapterUrl = canvas.toDataURL(param.file.type);
        // 开始上传图片
        self.startUploadImg(self.base64ToFile(self.imageChapterUrl,param.file.name),(data)=>{
          self.chapterAdd.image = data.filename
        }) //  开始上传图片
      }
    },
    uploadImg(param){
      // 750*360
      var canvas = document.createElement("canvas")
      // 读取图片的宽高
      var img = document.createElement("img")
      img.src =  URL.createObjectURL(param.file)
      img.width = 750
      img.height = 360
      var self = this
      img.onload = function (){
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext("2d").drawImage(img,0,0,img.width,img.height)
        self.imagecartoonUrl = canvas.toDataURL(param.file.type);
        // 开始上传图片
        self.startUploadImg(self.base64ToFile(self.imagecartoonUrl,param.file.name),(data)=>{
          self.cartoon.add.image = data.filename
        }) //  开始上传图片
      }
    },
    startUploadImg(file,callback,is_compress,width,height){
      // 开始上传文件到服务器
      var _headers = {
        "is_compress":is_compress==undefined?"false":is_compress,
        'Name':"community",
        "image_width":width,
        "image_height":height,
        "is-encode":"true",
      }
      this.$common.showLoading("正在上传封面图!")
      httpClient().setHeader(_headers).upload(file).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          callback(res.data)
          this.$message({message:"图片上传成功!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    beforeAvatarUpload(file){
      // 显示图片
      if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" ){
        this.$message({message:"文件类型["+file.type+"]不正确!",type:"error"})
        return
      }
      // 大小检测？
    },
    savecartoon(){
      if (this.cartoon.add.title == ""){
        this.$message({message:"请输入漫画的标题",type:"error"})
        return
      }
      if (this.cartoon.add.author == ""){
        // this.$message({message:"请输入漫画的作者",type:"error"})
        // return
      }
      if (this.cartoon.add.introduction == ""){
        // this.$message({message:"请输入漫画的简介",type:"error"})
        // return
      }
      if (this.cartoon.add.id > 0){
        // 编辑
        httpClient("UpdateCartoon").post(
          {"id":this.cartoon.add.id,
          "title":this.cartoon.add.title,
          "author":this.cartoon.add.author,
          "chapter":this.cartoon.add.chapter,
          "introduction":this.cartoon.add.introduction,
          "image":this.cartoon.add.image,
          "is_done":this.cartoon.add.is_done,
          "type":this.cartoon.add.type,
          "tags":this.cartoon.add.tags.join(",")}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.cartoonAddVisible = false
            this.getcartoonList()
            this.getcartoonSelectList()
          }else{
            this.$message({message:"添加漫画专辑失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }else{
        // 添加
        this.$common.showLoading("正在添加漫画专辑!")
        httpClient("AddCartoon").post(
          {"title":this.cartoon.add.title,
         "author":this.cartoon.add.author,
         // "chapter":this.cartoon.add.chapter,
         "introduction":this.cartoon.add.introduction,
         "image":this.cartoon.add.image,
         "is_done":this.cartoon.add.is_done,
         "type":this.cartoon.add.type,
         "tags":this.cartoon.add.tags.join(",")}
        ).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.cartoonAddVisible = false
            this.getcartoonList()
            this.getcartoonSelectList()
          }else{
            this.$message({message:"添加漫画专辑失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }
    },
    getChapterList(){
      httpClient("GetCartoonChapters").post(
        {"page":this.pager.page,
        "row":this.pager.rows,
        "id":this.search.id,
        "title":this.search.title,
        "checked":this.search.checked,
        "status":this.search.status,
        "cartoon_id":this.search.cartoon_id} ).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.tableData.length == 0){
            this.pager.total = 0
          }
          if (this.pager.page === 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:"读取漫画章节失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    chapterShow(row){
      this.chapterAddVisible = true
      this.chapterAdd = {
        id:0,
        title:"",
        cartoon_id:null,
        is_free:0,
        chapter:0,
        row:null,
        image:"",
      }
      this.imageChapterUrl = ""
      if(row !== undefined){
        if(row.image != ""){
          this.imageChapterUrl = row.pic_cdn + row.image
        }
        this.chapterAddTitle = "编辑漫画章节,ID:"+row.id
        this.chapterAdd = {
          row:row,
          id:row.id,
          title:row.title,
          cartoon_id:row.cartoon_id,
          is_free:row.is_free,
          chapter:row.chapter,
          image:row.image
        }
      }else{
        this.chapterAddTitle = "添加漫画章节"
      }
    },
    chapterUploadImage(row){
      this.chapterImageListVisible = true
      this.chapterUploadSubImage.row = row;
      this.chapterUploadSubImage.tableData = [];
      this.chapterUploadSubImage.dialog_title = "章节:"+row.title;
      this.getChapterSubImage()
    },
    getChapterSubImage(){
      this.$common.showLoading()
      httpClient("GetChaptersContent").post({chapter_id:this.chapterUploadSubImage.row.id}).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.chapterUploadSubImage.tableData = res.data
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    showcartoonList(){
      this.cartoonVisible = true
      this.getcartoonList()
    },
    handelcartoonSelection(rows){
      this.cartoonMultiSelection = rows;
    },
    multicartoonStatus(status){
      if(this.cartoonMultiSelection.length == 0){
        this.$message({message:"请选择小说专辑!",type:"error"})
        return;
      }
      var ids = []
      for(const row of this.cartoonMultiSelection){
        ids.push(row.id)
      }
      this.$common.showLoading()
      httpClient("BatchShelvesCartoon").post({status:status,ids:ids.join(",")}).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          for(const row of this.cartoonMultiSelection){
            row.status = status
          }
        }else{
          this.$message({message:"读取小说分类失败:"+res.msg,type:"error"})
        }
      })
    },
    multicartoonDel(){
      if(this.cartoonMultiSelection.length == 0){
        this.$message({message:"请选择漫画专辑!",type:"error"})
        return;
      }
      var ids = []
      for(const row of this.cartoonMultiSelection){
        ids.push(row.id)
      }
      this.$confirm("删除漫画专辑: " + ids.join(",") + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        this.$common.showLoading()
        httpClient("DelCartoon").post({id:ids.join(",")}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.getcartoonList()
            this.getChapterList()
            this.getcartoonSelectList()
          }else{
            this.$message({message:"删除漫画专辑:"+res.msg,type:"error"})
          }
        })
      })
    },
    getcartoonList(){
      httpClient("GetCartoon").post({
          "page":this.cartoon.pager.page,
          "row":this.cartoon.pager.row,
          "id":this.cartoon.search.id,
          "title":this.cartoon.search.title,
          "checked":this.cartoon.search.checked,
          "status":this.cartoon.search.status
      }).then((res)=>{
        if (res.code == 0){
          this.cartoon.tableData = res.data.list
          if (this.cartoon.pager.page === 1){
            this.cartoon.pager.total = res.data.total
          }
        }else{
          this.$message({message:"读取漫画分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    isDone(row,is_done){
      httpClient("CartoonDone").post({is_done:is_done,id:row.id}).then((res)=> {
        if(res.code == 0){
          row.is_done = is_done
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    cartoonEdit(row){
      this.cartoonAddVisible = true
      this.cartoon.add = {
        id:0,
        title:"",
        type:null,
        author:"",
        introduction:"",
        tags:[],
        image:"",
        chapter: 0,
      }
      this.imagecartoonUrl = ""
      if (row){
        this.cartoonAddTitle = "编辑漫画专辑,ID:"+row.id
        var select_tags = []
        if(row.tags){
          for(const tag of row.tags){
            select_tags.push(tag.Id)
          }
        }
        this.cartoon.add = {
          id:row.id,
          title:row.title,
          type:row.type,
          author:row.author,
          chapter: row.chapters,
          introduction:row.introduction,
          tags:select_tags,
          image:this.$store.getImage(row.image),
        }
        this.imagecartoonUrl = this.$store.getImage(row.image)
      }else{
        this.cartoonAddTitle = "添加漫画专辑"
      }
    },
    getTypes(){
      httpClient("communityType").post({type:0}).then((res)=>{
        if (res.code == 0){
          this.Types = res.data.list
        }else{
          this.$message({message:"读取漫画分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getTypesById(type){
      for(var i in this.Types){
        if (this.Types[i].id == type){
          return this.Types[i].title
        }
      }
      if(type == 0){
        return ""
      }
      return "未知分类:"+type
    },
    getTags(){
      httpClient("getTags").post({type:0}).then((res)=>{
        if (res.code == 0){
          this.Tags = res.data
        }else{
          this.$message({message:"读取漫画分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getcartoonSelectList(){
      httpClient("GetCartoonSelect").post().then((res)=>{
        if (res.code == 0){
          this.cartoonSelectList = res.data
        }else{
          this.$message({message:"读取漫画专辑下拉框失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    }
  }
}
</script>

<style>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>